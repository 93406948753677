<template>
    <div>
      <div class="vx-row mb-12"></div>
  
      <div class="vx-row mb-12">
        <div
          v-bind:class="[
            detail ? detailShow + ' vertical-divider' : '',
            detailHide,
          ]"
        >
          <data-table
            :responseData="responseData"
            :propsParams="params"
            :header="header"
            @reloadDataFromChild="reloadData"
          >
            <template slot="thead">
             
            </template>
            <template slot="tbody">
              <vs-tr
                :key="indextr"
                v-for="(tr, indextr) in responseData.creditNote"
              >
                <vs-td>
                  <template>
                    <vx-tooltip text="View Credit Note">
                      <vs-button
                        color="primary"
                        type="line"
                        icon-pack="feather"
                        v-on:click="handleView(tr)"
                        icon="icon-eye"
                      />
                    </vx-tooltip>
                  </template>
                </vs-td>
                <vs-td>
                  Credit Note Code : {{ tr.Code }}<br />
                  Debit Note Code : {{ responseData.debitNote[indextr].Code }}<br />
                  SOA Number : {{responseData.debitNote[indextr].SoaNumber}} <br>
                  Customer Return Code : {{ tr.SalesReturnCode==""?"-":tr.SalesReturnCode }}<br />
                  Customer Return Reference Code : {{ responseData.salesReturn[indextr].SrReferenceCode }}<br />
                  Type : {{getSalesReturnType(tr.SalesReturnID)}} 
                  <template v-if="tr.SalesReturnCode==''">
                    {{tr.Type}}
                  </template>
                </vs-td>
                <vs-td>
                  Code : {{ tr.CustomerCode }}<br />
                  Name : {{ tr.CustomerName }}<br />
                </vs-td>
                <vs-td>
                  {{ dateFormat(tr.CreatedAt) }}
                </vs-td>
                <vs-td>
                  Initial Invoice : {{initialInvoice[indextr].Code}} 
                  <br>
                  Payment For Invoice : {{tr.InvoiceCode}} 
                  <br>
                  Note : {{ tr.Note }}
                </vs-td>
              </vs-tr>
            </template>
          </data-table>
        </div>
        <transition name="detail-fade">
          <div
            v-if="detail"
            v-bind:class="[
              detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
              detailHide,
            ]"
          >
            <div>
              <vs-col
                style="padding-bottom: 8px"
                vs-offset="8"
                vs-type="flex"
                vs-justify="rigth"
                vs-align="rigth"
                vs-w="4"
              >
                <vs-button
                  size="small"
                  v-on:click="closeDetail"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
                  >Close</vs-button
                >
              </vs-col>
              <form-view :selected="selectedData" />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </template>
  <script>
  import formView from "./form_view.vue"
  import {
    dataTableCreditNote,
  } from "../../../../services/api/credit_note";
  import moment from "moment";
  export default {
    props: {
      selected: Object,
      option: Object,
    },
    components: {
      "form-view": formView
    },
    data() {
      return {
        params: {
          search: "",
          length: 10,
          page: 1,
          order: "desc",
          sort: "credit_notes.created_at",
        },
  
        header: [
          {
            text: "Action",
            sortable: false,
          },
          {
            text: "Code",
            value: "credit_notes.code",
          },
          {
            text: "Customer",
            value: "credit_notes.customer_name",
            // width: '5%'
          },
          {
            text: "Date",
            sortable: false,
            // width: '5%'
          },
          {
            text: "Note",
            sortable: false,
            // width: '5%'
          },
        ],
        responseData: {},
        detailShow: "vx-col md:w-1/2 w-full mb-base",
        detailHide: "core vx-col md:w-1/1 w-full mb-base",
        detail: false,
        selectedData: {},
        validate: [],
        validateAll: false,
        salesReturn:[],
        salesReturnType:[],
        initialInvoice: [],
      };
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
      dateFormat(date) {
        if (date) {
          return moment(String(date)).format("MM/DD/YYYY hh:mm");
        }
      },
      addValidate(ID) {
        console.log(this.validate.length);
        if (this.validate.includes(ID)) {
          this.validate.splice(this.validate.indexOf(ID), 1);
        } else {
          this.validate.push(ID);
        }
      },
      getSalesReturnType(salesReturnID) {
        try {
          const salesReturn = this.salesReturn.filter(v => {
            return v.ID == salesReturnID
          })[0]
          console.log(salesReturn, salesReturnID)
          return this.salesReturnType.filter(v => {
            return v.ID == salesReturn.SalesReturnTypeID
          })[0].Name
        } catch (err) {
          return ""
        }
      },
      addAllValidate() {
        console.log(this.data, this.validateAll);
        if (this.validateAll == false) {
          for (var i in this.data) {
            if (!this.validate.includes(this.data[i].ID)) {
              this.validate.push(this.data[i].ID);
            }
          }
        } else {
          this.validate = [];
        }
      },
      closeDetail(params = false) {
        window.scrollTo(0, 0);
        this.detail = false;
        this.selectedData = {
          ID: 0,
        };
        if (params == true) {
          this.reloadData(this.params);
        }
      },
      handleView(selected) {
        this.selectedData = selected
        this.detail = true
        // this.$vs.loading();
        //   this.$vs.loading.close();
        // const creditNote = dataCreditNote(selected)
        // creditNote.then(r => {
        //   console.log(r)
          
        // })
  
      },
      handleDelete(selected) {
        console.log(selected)
      },
      reloadData(params) {
        this.params = params;
        this.params.status = 6
        this.$vs.loading();
  
        const salesReturn = dataTableCreditNote(this.params);
        salesReturn.then((r) => {
          if (r.code == 500) {
            this.$vs.loading.close();
          } else if (r.code == 200) {
            this.$vs.loading.close();
            this.data = r.data.creditNote;
            this.responseData = r.data;
            this.responseData.length = r.data.length;
            this.responseData.recordsTotal = r.data.recordsTotal;
            this.salesReturn = r.data.salesReturn
            this.salesReturnType = r.data.salesReturnType
            this.initialInvoice = r.data.initialInvoices
            //   this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
      },
    },
  };
  </script>
   <style scoped>
  .nonfixed {
    position: inherit;
    padding-left: 20px;
  }
  .core-enter-active {
    transition: all 0.3s ease;
  }
  .core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .core {
    -webkit-transform-origin-y: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }
  .vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
  }
  .vertical-divider {
    border-right: 1px solid #7367f0;
    /* min-height: 800px; */
    /* height: 100%; */
    -webkit-mask-position-y: fixed;
    /* padding: 5px; */
  }
  .vs-con-table.stripe .tr-values:nth-child(2n) {
    background: beige;
  }
  .colored {
    border: 1px solid #7367f0;
    position: fixed;
    left: 4%;
    top: 40%;
    max-width: 45%;
    z-index: 999999999999;
    background: antiquewhite;
    background-color: antiquewhite;
  }
  </style>
