<template>
    <div>
        <vx-card title="Credit Note">
            <vs-tabs :color="colorx" v-model="tabs">
                <vs-tab @click="colorx = 'danger'" label="Customer Return Complete">
                    <div class="con-tab-ejemplo">
                        <complete/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Draft">
                    <div class="con-tab-ejemplo">
                        <draft/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Waiting Approval">
                    <div class="con-tab-ejemplo">
                        <waiting/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Approved">
                    <div class="con-tab-ejemplo">
                        <approved/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Rejected">
                    <div class="con-tab-ejemplo">
                        <rejected/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Released">
                    <div class="con-tab-ejemplo">
                        <released/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Applied">
                    <div class="con-tab-ejemplo">
                        <applied/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Reversed">
                    <div class="con-tab-ejemplo">
                        <reversed/>
                    </div>
                </vs-tab>
                
            </vs-tabs>
        </vx-card>
    </div>
</template>
<script>
import complete from "./complete.vue";
import draft from "./draft.vue";
import waiting from "./waiting_approval.vue";
import approved from "./approved.vue";
import rejected from "./rejected.vue";
import released from './released.vue';
import applied from './applied.vue';
import reversed from './reversed.vue';


export default {
    components: {
        complete,
        draft,
        rejected,
        approved,
        waiting,
        released,
        applied,
        reversed,
    },
    data:() => ({
        colorx: "danger",
        tabs: 0,
    })
}
</script>